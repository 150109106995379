import React, { useState, useRef, useEffect } from "react";
import view from "./dropdown.module.scss";

const DropdownOption = ({ type, placeholder, values, addFilter, label, sorter }) => {
  const [option, setOption] = useState(placeholder);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleGlobalClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); 
      }
    };

    window.addEventListener('click', handleGlobalClick);

    return () => {
      window.removeEventListener('click', handleGlobalClick);
    };
  }, []);

  const renderOptions = () => {
    const keys = Object.keys(values);
    const sortedKeys = sorter ? keys.sort(sorter) : keys;
    return sortedKeys.map((key, idx) => (
      <li key={idx} className={view["sub-dropdown__item"]} onClick={() => {
        onSelected(key);
      }}>
        {`${key} (${values[key]})`}
      </li>
    ));
  };

  const onSelected = (value) => {
    addFilter({ value: value !== placeholder ? value : "", type });
    setOption(value);
    setIsOpen(false); 
    document.activeElement.blur();
  };

  return (
    <ul className={view["dropdowns"]} style={{ padding: 0 }} ref={dropdownRef}>
      <li className={view["dropdown"]} style={{ width: "100%" }}>
        <a tabIndex="0" className={view["dropdown__inner"]} onClick={toggleDropdown}>
          <p className={view["dropdown__name"]}>{label}</p>
          <p className={view["dropdown__value"]}>{option}</p>
        </a>
        {isOpen && (
          <ul tabIndex="0" className={view["sub-dropdown"]}>
            <li className={view["sub-dropdown__item"]} onClick={() => { onSelected(placeholder) }}>
              {placeholder}
            </li>
            {renderOptions()}
          </ul>
        )}
      </li>
    </ul>
  );
}

export default DropdownOption;
