function IsNewDisplay(IsNew){
  return (IsNew === true ? "New" : (IsNew === false) ? "Used" : "")
}
module.exports = {
  IsNewDisplay:(IsNew) => {
    return (IsNew === true ? "New" : (IsNew === false) ? "Used" : "")
  },
  IsCertifiedDisplay:(IsCertified) => {
    return (IsCertified === true ? "Certified " : "" )
  },

  configurableCompareString: (theString, compareVehicleInfo) => {
    let finalString = theString
    finalString = finalString.replace("{{compareModel}}", compareVehicleInfo.compareModel)
    finalString = finalString.replace("{{mainModel}}", compareVehicleInfo.mainModel)
    return finalString
  },
  configurableString: (theString, VehicleInfo,  MainPhotoUrl, configurablePriceInfo) => {
    let finalString = theString
    finalString = finalString.replace("{{new}}", IsNewDisplay(VehicleInfo.IsNew))
    finalString = finalString.replace("{{vin}}", VehicleInfo.VIN)
    finalString = finalString.replace("{{year}}", VehicleInfo.Year)
    finalString = finalString.replace("{{make}}", VehicleInfo.Make)
    finalString = finalString.replace("{{model}}", VehicleInfo.Model)
    finalString = finalString.replace("{{bodytype}}", VehicleInfo.BodyType)
    finalString = finalString.replace("{{status}}", VehicleInfo.VehicleStatus)
    finalString = finalString.replace("{{image}}", MainPhotoUrl)
    finalString = finalString.replace("{{price}}", configurablePriceInfo)
    return finalString
  },
  /*
  * remove all spaces " " and replace with dash "-"
  *
  * */
  configurableLink: (theLink, VehicleInfo) => {
    let finalLink = theLink
    finalLink = finalLink.replace("{{new}}", IsNewDisplay(VehicleInfo.IsNew))
    finalLink = finalLink.replace("{{vin}}", VehicleInfo.VIN)
    finalLink = finalLink.replace("{{year}}", VehicleInfo.Year)
    finalLink = finalLink.replace("{{make}}", VehicleInfo.Make.replace(" ", "-"))
    finalLink = finalLink.replace("{{model}}", VehicleInfo.Model.replace(" ", "-"))
    finalLink = finalLink.replace("{{bodytype}}", VehicleInfo.BodyType.replace(" ", "-"))
    finalLink = finalLink.replace("{{status}}", VehicleInfo.VehicleStatus ? VehicleInfo.VehicleStatus.replace(" ", "-") : "NA")
    finalLink = finalLink.replace("/-/", "/")
    return finalLink
  },
  /*
  * Slug add / to the beginning of the final return
  *
  * */
  configurableSlug: (theSlug, VehicleInfo) => {
    let finalSlug = theSlug
    finalSlug = finalSlug.replace("{{new}}", IsNewDisplay(VehicleInfo.IsNew))
    finalSlug = finalSlug.replace("{{vin}}", VehicleInfo.VIN)
    finalSlug = finalSlug.replace("{{year}}", VehicleInfo.Year)
    finalSlug = finalSlug.replace("{{make}}", VehicleInfo.Make.replace(" ", "-"))
    finalSlug = finalSlug.replace("{{model}}", VehicleInfo.Model.replace(" ", "-"))
    finalSlug = finalSlug.replace("{{bodytype}}", VehicleInfo.BodyType.replace(" ", "-"))
    finalSlug = finalSlug.replace("{{status}}", VehicleInfo.VehicleStatus ? VehicleInfo.VehicleStatus.replace(" ", "-") : "NA")
    finalSlug = finalSlug.replace("/-/", "/")
    return "/" + (finalSlug == "" ? VehicleInfo.VIN : finalSlug)
  },
  /*
  * If you don't have the VehicleInfo data you can pass them in a indiviual items
  * */
  configurableSlugManual: (theSlug, VIN, Year, Make, Model, BodyType,IsNew) => {
    let finalSlug = theSlug
    finalSlug = finalSlug.replace("{{new}}", IsNewDisplay(IsNew))
    finalSlug = finalSlug.replace("{{vin}}", VIN)
    finalSlug = finalSlug.replace("{{year}}", Year)
    finalSlug = finalSlug.replace("{{make}}", Make.replace(" ", "-"))
    finalSlug = finalSlug.replace("{{model}}", Model.replace(" ", "-"))
    finalSlug = finalSlug.replace("{{bodytype}}", BodyType.replace(" ", "-"))
    finalSlug = finalSlug.replace("/-/", "/")
    return "/" + (finalSlug == "" ? VIN : finalSlug)
  }
}